/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TeamRole {
    LEAGUE_OWNER = 'league_owner',
    COMMISSIONER = 'commissioner',
    OWNER = 'owner',
    MANAGER = 'manager',
    PLAYER = 'player',
    NON_PLAYER = 'non_player',
}
