/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApiResponse_BillingFrequency {
    UNKNOWN = 'unknown',
    DAILY = 'daily',
    THREE_DAY = 'three_day',
    WEEK = 'week',
    MONTH = 'month',
    THREE_MONTH = 'three_month',
    SIX_MONTH = 'six_month',
    YEAR = 'year',
    LIFETIME = 'lifetime',
}
