/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationAvailableEntitlement = {
    entitlementName: OrganizationAvailableEntitlement.entitlementName;
    displayName: string;
};

export namespace OrganizationAvailableEntitlement {

    export enum entitlementName {
        TEAMSNAP_PLUS_CONTENT = 'teamsnap_plus_content',
        ENABLE_FLAG_FOOTBALL_CONTENT = 'enable_flag_football_content',
    }


}

