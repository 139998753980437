/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NamedParameters_typeof_buildSubscriptionInfo_ = {
    subscriptionInfo: {
        promotional?: {
            expirationDate: string;
        };
        trial?: {
            chargeDate: string;
        };
        premium?: {
            billingIssuesDetectedAt?: string;
            renewDate: string;
        };
        initialPurchaseDate: string;
        willRenew: boolean;
        billingFrequency: NamedParameters_typeof_buildSubscriptionInfo_.billingFrequency;
        platform: NamedParameters_typeof_buildSubscriptionInfo_.platform;
        displayPrice: string;
        displayName: string;
        id: string;
        type: NamedParameters_typeof_buildSubscriptionInfo_.type;
    };
};

export namespace NamedParameters_typeof_buildSubscriptionInfo_ {

    export enum billingFrequency {
        UNKNOWN = 'unknown',
        DAILY = 'daily',
        THREE_DAY = 'three_day',
        WEEK = 'week',
        MONTH = 'month',
        THREE_MONTH = 'three_month',
        SIX_MONTH = 'six_month',
        YEAR = 'year',
        LIFETIME = 'lifetime',
    }

    export enum platform {
        UNKNOWN = 'unknown',
        IOS = 'ios',
        ANDROID = 'android',
        PROMOTIONAL = 'promotional',
        WEB = 'web',
    }

    export enum type {
        UNKNOWN = 'unknown',
        TRIAL = 'trial',
        PREMIUM = 'premium',
        PROMOTIONAL = 'promotional',
    }


}

