import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Registration, RegistrationsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<Registration[]> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadRegistrationsById = createAsyncThunk('registrations/loadById', (id: number) =>
  handleErrors(() => RegistrationsService.getRegistrations(id))
);

export const loadRegistrationsByFormId = createAsyncThunk('registrations/loadByFormId', (formId: number) =>
  handleErrors(() => RegistrationsService.getRegistrations(undefined, formId))
);

export const loadRegistrationsByDivisionId = createAsyncThunk('registrations/loadByDivisionId', (divisionId: number) =>
  handleErrors(() => RegistrationsService.getRegistrations(undefined, undefined, divisionId))
);

export const slice = createSlice({
  name: 'registrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadRegistrationsById.pending, asyncPendingReducer);
    builder.addCase(loadRegistrationsById.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
    builder.addCase(loadRegistrationsByFormId.pending, asyncPendingReducer);
    builder.addCase(loadRegistrationsByFormId.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
    builder.addCase(loadRegistrationsByDivisionId.pending, asyncPendingReducer);
    builder.addCase(loadRegistrationsByDivisionId.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useRegistrationsStateSelector = () => useAppSelector((state) => state.registrations);
export const useRegistrationsSelector = () => useAppSelector((state) => state.registrations.data);

export default slice.reducer;
