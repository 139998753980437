/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Registration } from '../models/Registration';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RegistrationsService {

    /**
     * Gets all registrations by Registration ID, Registration Form ID, or Season ID. Must provide one id for filtering, else returns an empty list.
     * @param id
     * @param formId
     * @param divisionId
     * @returns Registration Success
     * @throws ApiError
     */
    public static getRegistrations(
        id?: number,
        formId?: number,
        divisionId?: number,
    ): CancelablePromise<Array<Registration>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registrations',
            query: {
                'id': id,
                'formId': formId,
                'divisionId': divisionId,
            },
        });
    }

}
