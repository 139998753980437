/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SeasonType } from '../models/SeasonType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SeasonService {

    /**
     * Returns divisions by seasonID
     * @param seasonId
     * @returns SeasonType Success
     * @throws ApiError
     */
    public static divisions(
        seasonId: number,
    ): CancelablePromise<Array<SeasonType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/seasons/{seasonId}/divisions',
            path: {
                'seasonId': seasonId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * Returns seasons by organizationId
     * @param organizationId
     * @param isArchived
     * @param legacySeasons
     * @returns SeasonType Success
     * @throws ApiError
     */
    public static seasonIndex(
        organizationId: number,
        isArchived?: boolean,
        legacySeasons?: string,
    ): CancelablePromise<Array<SeasonType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/seasons/',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'isArchived': isArchived,
                'legacySeasons': legacySeasons,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
