import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { OpenAPI } from 'core/api';
import { API_URL } from 'core/constants';

import { Root } from 'pages/root';

import { store } from './state/store';

import '@teamsnap/snap-ui/build/snap-ui.min.css';
import '@teamsnap/teamsnap-ui/src/css/teamsnap-ui.scss';

OpenAPI.BASE = API_URL;
OpenAPI.WITH_CREDENTIALS = true;

render(
  <StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
