/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetBillingTransactionForStripeResponse = {
    id: string;
    amountAttempted: string;
    amountCharged: string;
    currency: string;
    last4?: string;
    type: GetBillingTransactionForStripeResponse.type;
    teamId: string;
    created: string;
    processedAt: string;
};

export namespace GetBillingTransactionForStripeResponse {

    export enum type {
        PURCHASE = 'purchase',
    }


}

