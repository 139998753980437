/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrganizationContentEntitlement = {
    entitlementName: OrganizationContentEntitlement.entitlementName;
    access: boolean;
};

export namespace OrganizationContentEntitlement {

    export enum entitlementName {
        TEAMSNAP_PLUS_CONTENT = 'teamsnap_plus_content',
        ENABLE_FLAG_FOOTBALL_CONTENT = 'enable_flag_football_content',
    }


}

