/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApiResponse_SubscriptionType {
    UNKNOWN = 'unknown',
    TRIAL = 'trial',
    PREMIUM = 'premium',
    PROMOTIONAL = 'promotional',
}
