/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SubscriptionInfo = {
    id: string;
    type: SubscriptionInfo.type;
    displayName: string;
    displayPrice: string;
    initialPurchaseDate: string;
    platform: SubscriptionInfo.platform;
    billingFrequency: SubscriptionInfo.billingFrequency;
    willRenew: boolean;
    premium?: {
        renewDate: string;
        billingIssuesDetectedAt?: string;
    };
    trial?: {
        chargeDate: string;
    };
    promotional?: {
        expirationDate: string;
    };
};

export namespace SubscriptionInfo {

    export enum type {
        UNKNOWN = 'unknown',
        TRIAL = 'trial',
        PREMIUM = 'premium',
        PROMOTIONAL = 'promotional',
    }

    export enum platform {
        UNKNOWN = 'unknown',
        IOS = 'ios',
        ANDROID = 'android',
        PROMOTIONAL = 'promotional',
        WEB = 'web',
    }

    export enum billingFrequency {
        UNKNOWN = 'unknown',
        DAILY = 'daily',
        THREE_DAY = 'three_day',
        WEEK = 'week',
        MONTH = 'month',
        THREE_MONTH = 'three_month',
        SIX_MONTH = 'six_month',
        YEAR = 'year',
        LIFETIME = 'lifetime',
    }


}

