import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RolesService, RoleType } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<RoleType[]> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadRolesForUser = createAsyncThunk('roles/loadByUserId', () =>
  handleErrors(() => RolesService.getRolesForUser())
);

export const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadRolesForUser.pending, asyncPendingReducer);
    builder.addCase(loadRolesForUser.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useRolesStateSelector = () => useAppSelector((state) => state.roles);
export const useRolesSelector = () => useAppSelector((state) => state.roles.data);

export default slice.reducer;
