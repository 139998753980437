/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramType } from '../models/ProgramType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Returns programs for an organization
     * @param organizationId
     * @returns ProgramType Success
     * @throws ApiError
     */
    public static programIndex(
        organizationId?: number,
    ): CancelablePromise<Array<ProgramType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/programs/',
            query: {
                'organizationId': organizationId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
