/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NamedParameters_typeof_translateSubscriptionPlatform_ = {
    capability: NamedParameters_typeof_translateSubscriptionPlatform_.capability;
};

export namespace NamedParameters_typeof_translateSubscriptionPlatform_ {

    export enum capability {
        UNKNOWN = 'unknown',
        IOS = 'ios',
        ANDROID = 'android',
        PROMOTIONAL = 'promotional',
        WEB = 'web',
    }


}

