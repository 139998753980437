import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetBillingTransactionForStripeResponse, BillingTransactionsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<GetBillingTransactionForStripeResponse> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadTransactionForInvoiceNumber = createAsyncThunk('billing/getStripeTransaction', (invoiceNumber: string) =>
  handleErrors(() => BillingTransactionsService.getStripeTransaction(invoiceNumber))
);

export const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTransactionForInvoiceNumber.pending, asyncPendingReducer);
    builder.addCase(loadTransactionForInvoiceNumber.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useBillingStateSelector = () => useAppSelector((state) => state.billing);
export const useBillingSelector = () => useAppSelector((state) => state.billing.data);

export default slice.reducer;
export const { clearData } = slice.actions;
