/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBillingTransactionForStripeResponse } from '../models/GetBillingTransactionForStripeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingTransactionsService {

    /**
     * Get billing information for a stripe transaction
     * @param invoiceNumber
     * @returns GetBillingTransactionForStripeResponse Success
     * @throws ApiError
     */
    public static getStripeTransaction(
        invoiceNumber: string,
    ): CancelablePromise<GetBillingTransactionForStripeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing_transactions/stripe/{invoiceNumber}/',
            path: {
                'invoiceNumber': invoiceNumber,
            },
            errors: {
                400: `Validation Error`,
            },
        });
    }

}
