/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleType } from '../models/RoleType';
import type { SaveSupportRoleParams } from '../models/SaveSupportRoleParams';
import type { SupportRole } from '../models/SupportRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * Add your current user to organizations or programs. Accepts array of roles.
     * @param requestBody
     * @returns SupportRole Success
     * @throws ApiError
     */
    public static saveSupportRoles(
        requestBody?: SaveSupportRoleParams,
    ): CancelablePromise<Array<SupportRole>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/roles/supportRoles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * Returns support roles for an organization and user
     * @param organizationId
     * @returns SupportRole Success
     * @throws ApiError
     */
    public static supportRolesIndex(
        organizationId?: number,
    ): CancelablePromise<Array<SupportRole>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/supportRoles',
            query: {
                'organizationId': organizationId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * Gets all roles for the current logged in user
     * @returns RoleType Success
     * @throws ApiError
     */
    public static getRolesForUser(): CancelablePromise<Array<RoleType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
        });
    }

    /**
     * Deletes support role by id.
     * @param roleId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRole(
        roleId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/{roleId}/delete',
            path: {
                'roleId': roleId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
