/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NamedParameters_typeof_translateSubscriptionType_ = {
    capability: NamedParameters_typeof_translateSubscriptionType_.capability;
};

export namespace NamedParameters_typeof_translateSubscriptionType_ {

    export enum capability {
        UNKNOWN = 'unknown',
        TRIAL = 'trial',
        PREMIUM = 'premium',
        PROMOTIONAL = 'promotional',
    }


}

