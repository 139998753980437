/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { OrganizationAvailableEntitlement } from '../models/OrganizationAvailableEntitlement';
import type { OrganizationCreate } from '../models/OrganizationCreate';
import type { OrganizationDetails } from '../models/OrganizationDetails';
import type { OrganizationSettings } from '../models/OrganizationSettings';
import type { OrganizationStubInfo } from '../models/OrganizationStubInfo';
import type { OrganizationUpdate } from '../models/OrganizationUpdate';
import type { TournamentOrganizationInfo } from '../models/TournamentOrganizationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * Get entitlements available for organizations
     * @returns OrganizationAvailableEntitlement Success
     * @throws ApiError
     */
    public static availableOrganizationContentEntitlements(): CancelablePromise<Array<OrganizationAvailableEntitlement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/entitlements/',
        });
    }

    /**
     * Get organizations by the filter
     * @param tsDivisionId
     * @param searchTerm
     * @returns Organization Success
     * @throws ApiError
     */
    public static getOrganizations(
        tsDivisionId?: number,
        searchTerm?: string,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/',
            query: {
                'tsDivisionId': tsDivisionId,
                'searchTerm': searchTerm,
            },
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Creates organization for the current user
     * @param requestBody
     * @returns Organization Success
     * @throws ApiError
     */
    public static createOrganization(
        requestBody?: OrganizationCreate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * Get tournament organization by id
     * @param tournamentOrgId
     * @param checkInUse
     * @returns TournamentOrganizationInfo Success
     * @throws ApiError
     */
    public static getTournamentOrganization(
        tournamentOrgId: number,
        checkInUse?: boolean,
    ): CancelablePromise<TournamentOrganizationInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/tournament_organization/{tournamentOrgId}',
            path: {
                'tournamentOrgId': tournamentOrgId,
            },
            query: {
                'checkInUse': checkInUse,
            },
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Get stub of organization data for legacy C&L org
     * @param tsPersistentUuid
     * @param checkInUse
     * @returns OrganizationStubInfo Success
     * @throws ApiError
     */
    public static getOrgStubByTsPersistentUuid(
        tsPersistentUuid: string,
        checkInUse?: boolean,
    ): CancelablePromise<OrganizationStubInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/legacy_org_info/{tsPersistentUuid}',
            path: {
                'tsPersistentUuid': tsPersistentUuid,
            },
            query: {
                'checkInUse': checkInUse,
            },
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Loads the organization settings
     * @param organizationId
     * @returns OrganizationSettings Success
     * @throws ApiError
     */
    public static getSettings(
        organizationId: number,
    ): CancelablePromise<OrganizationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/settings',
            path: {
                'organizationId': organizationId,
            },
        });
    }

    /**
     * Loads the organization details
     * @param organizationId
     * @returns OrganizationDetails Success
     * @throws ApiError
     */
    public static getDetails(
        organizationId: number,
    ): CancelablePromise<OrganizationDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/details',
            path: {
                'organizationId': organizationId,
            },
        });
    }

    /**
     * Updates an existing organization
     * @param organizationId
     * @param requestBody
     * @returns Organization Success
     * @throws ApiError
     */
    public static updateOrganization(
        organizationId: number,
        requestBody?: OrganizationUpdate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organizations/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

}
