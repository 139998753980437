import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSubscriptionInfoForUserResponse, SubscriptionsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<GetSubscriptionInfoForUserResponse> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadSubscriptionsForUser = createAsyncThunk('subscriptions/getByUserId', (userId: number) =>
  handleErrors(() => SubscriptionsService.subscriptions(userId))
);

export const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSubscriptionsForUser.pending, asyncPendingReducer);
    builder.addCase(loadSubscriptionsForUser.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useSubscriptionsStateSelector = () => useAppSelector((state) => state.subscriptions);
export const useSubscriptionsSelector = () => useAppSelector((state) => state.subscriptions.data);

export default slice.reducer;
export const { clearData } = slice.actions;
