/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSubscriptionsForTeamsResponse } from '../models/GetSubscriptionsForTeamsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamSubscriptionsService {

    /**
     * Returns subscriptions for a team
     * @param userId
     * @param ids
     * @returns GetSubscriptionsForTeamsResponse Success
     * @throws ApiError
     */
    public static getTeamSubscriptions(
        userId?: number,
        ids?: string,
    ): CancelablePromise<GetSubscriptionsForTeamsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/teams/',
            query: {
                'userId': userId,
                'ids': ids,
            },
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

}
