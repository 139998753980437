/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NamedParameters_typeof_translateSubscriptionCapability_ = {
    capability: NamedParameters_typeof_translateSubscriptionCapability_.capability;
};

export namespace NamedParameters_typeof_translateSubscriptionCapability_ {

    export enum capability {
        UNKNOWN = 'unknown',
        VIEW_PREMIUM_CONTENT = 'view_premium_content',
    }


}

