import './createOrganizationModal.scss';
import * as Yup from 'yup';
import timezones from 'core/timezones';
import countries from 'core/countries';
import sports from 'core/sports';
import { Modal, Pill } from '@teamsnap/teamsnap-ui';
import { FieldArray, Form, Formik } from 'formik';
import { FormField, FormFieldType, LookupField } from 'components/shared';
import { LabelButton } from '@teamsnap/snap-ui';
import { ApiError, ContentEntitlementName, OrganizationAvailableEntitlement, OrganizationCreate, OrganizationService, OrganizationsService, OrganizationUpdate, TournamentOrganizationInfo } from 'core/api';
import { useEffect, useState } from 'react';
import { LookupOption } from 'components/shared/LookupField/LookupField';

const initialValues = {
  additionalLegacyOrgUuids: [],
  name: '',
  ownerEmail: '',
  newOwnerEmail: '',
  ownerFirstName: '',
  ownerLastName: '',
  country: '',
  zipCode: '',
  timezone: '',
  sport: '',
  mailingAddress: '',
  mailingAddress2: '',
  mailingCity: '',
  mailingState: '',
  mailingCountry: '',
  mailingZipCode: '',
  updateAllSeasonsAddress: false,
  businessType: '',
  plan: '',
  registrationType: '',
  creditCardProcessingFeeRate: undefined,
  creditCardProcessingFeeBase: undefined,
  achProcessingFeeRate: undefined,
  achProcessingFeeBase: undefined,
  numberOfTeamsPaidFor: undefined,
  playerCount: undefined,
  websiteUrl: '',
  notes: '',
  isTest: false,
  paymentProvider: 'stripe',
  tournamentOrgId: undefined,
  tsPersistentUuid: undefined,
  contentEntitlements: {},
};

type FormValues = Omit<
  typeof initialValues,
  | 'playerCount'
  | 'tournamentOrgId'
  | 'numberOfTeamsPaidFor'
  | 'creditCardProcessingFeeRate'
  | 'creditCardProcessingFeeBase'
  | 'achProcessingFeeRate'
  | 'achProcessingFeeBase'
  | 'additionalLegacyOrgUuids'
  | 'tsPersistentUuid'
> & {
  tsPersistentUuid?: number;
  additionalLegacyOrgUuids?: string[];
  playerCount?: number;
  tournamentOrgId?: number;
  numberOfTeamsPaidFor?: number;
  creditCardProcessingFeeRate?: number;
  creditCardProcessingFeeBase?: number;
  achProcessingFeeRate?: number;
  achProcessingFeeBase?: number;
  contentEntitlements: {
    [key: string]: boolean
  };
};

export type EditOrganizationData = FormValues & {
  id: number;
  tournamentOrg?: TournamentOrganizationInfo;
  legacyOrgs: { label: string; value: string }[];
};

type Props = {
  show: boolean;
  onClose: (reload: boolean) => void;
  editOrganization?: EditOrganizationData;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required field.'),
  ownerEmail: Yup.string().email('Invalid email').required('Required field.'),
  newOwnerEmail: Yup.string().email('Invalid email'),
  ownerFirstName: Yup.string().required('Required field.'),
  ownerLastName: Yup.string().required('Required field.'),
  country: Yup.string().required('Required field.'),
  zipCode: Yup.string().required('Required field.'),
  timezone: Yup.string().required('Required field.'),
  sport: Yup.string().required('Required field.'),
  businessType: Yup.string().required('Required field.'),
  plan: Yup.string().required('Required field.'),
  registrationType: Yup.string().required('Required field.'),
  creditCardProcessingFeeRate: Yup.number().max(9999, 'the value must be equal to or less than 9999'),
  creditCardProcessingFeeBase: Yup.number().max(9999, 'the value must be equal to or less than 9999'),
  achProcessingFeeRate: Yup.number().max(9999, 'the value must be equal to or less than 9999'),
  achProcessingFeeBase: Yup.number().max(9999, 'the value must be equal to or less than 9999'),
  playerCount: Yup.number().required('Required field.').max(9999, 'the value must be equal to or less than 9999'),
  paymentProvider: Yup.string().required('Required field.'),
  contentEntitlements: Yup.object(),
});

const CreateOrganizationModal = ({ show, onClose, editOrganization: editOrg }: Props) => {
  const [tournamentOrgOptions, setTournamentOrgOptions] = useState<LookupOption[]>([]);
  const [legacyOrgOptions, setLegacyOrgOptions] = useState<LookupOption[]>([]);
  const [canAddLegacyOrgs, setCanAddLegacyOrgs] = useState(true);
  const [contentEntitlements, setContentEntitlements] = useState<OrganizationAvailableEntitlement[]>([]);

  useEffect(() => {
    const tstOrg = editOrg?.tournamentOrg;
    if (tstOrg) {
      setTournamentOrgOptions([{ id: `${tstOrg.id}`, title: `${tstOrg.name}`, subtitle: `${tstOrg.id}` }]);
    }
    setCanAddLegacyOrgs(!editOrg || !!editOrg.legacyOrgs?.length);

  }, [editOrg]);

  useEffect(() => {
    OrganizationsService.availableOrganizationContentEntitlements()
      .then(setContentEntitlements)
  }, [editOrg])

  const onSearchTournamentOrg = async (searchTerm: string) => {
    try {
      const data = await OrganizationsService.getTournamentOrganization(+searchTerm, true);

      setTournamentOrgOptions([{ id: `${data.id}`, title: data.name, subtitle: `${data.id}` }]);
    } catch (error) {
      setTournamentOrgOptions([]);
      if (error instanceof ApiError) {
        return error.body;
      } else {
        return 'Oops! Something went wrong.';
      }
    }
  };

  const onSearchLegacyOrg = async (searchTerm: string) => {
    try {
      const data = await OrganizationsService.getOrgStubByTsPersistentUuid(searchTerm, true);

      setLegacyOrgOptions([{ id: data.legacyUuid, title: data.name }]);
    } catch (error) {
      setLegacyOrgOptions([]);
      if (error instanceof ApiError) {
        return error.body;
      } else {
        return 'Oops! Something went wrong.';
      }
    }
  };

  const transformValuesToRequestBody = (values: FormValues): OrganizationCreate | OrganizationUpdate => {
    const contentEntitlements = Object.keys(values.contentEntitlements).map(key => ({
      entitlementName: key as ContentEntitlementName,
      access: values.contentEntitlements[key]
    }))

    return {
      ...values,
      contentEntitlements: contentEntitlements,
      mailingZip: values.mailingZipCode,
      tournamentOrgId: values.tournamentOrgId ? +values.tournamentOrgId : null,
    }
  }

  const onSubmit = async (values: FormValues) => {
    const requestBody = transformValuesToRequestBody(values)

    try {
      if (editOrg) {
        const dirtyFields = Object.keys(values).filter(
          (key) => values[key as keyof FormValues] !== editOrg[key as keyof FormValues]
        );
        const hasOwnerFieldsChange = dirtyFields.some((key) => ['ownerEmail', 'newOwnerEmail'].includes(key));

        await OrganizationsService.updateOrganization(editOrg.id, {
          ...requestBody,
          id: editOrg?.id,
          ownerEmail: values.newOwnerEmail ? values.newOwnerEmail : values.ownerEmail,
          resendInvite: hasOwnerFieldsChange,
        });
      } else {
        await OrganizationsService.createOrganization(requestBody);
      } 
      onClose(true);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Modal
      heading={`${editOrg ? 'Edit' : 'Create'} Organization`}
      show={show}
      showClose
      allowOverlayClose
      closeFn={() => onClose(false)}
      style={{ minWidth: '1013px' }}
    >
      <Formik initialValues={editOrg || initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form id="organization-form">
          <h3 className="sui-text-desktop-8 sui-mb-1">Organization information</h3>
          <section className="fields-group sui-p-2">
            <FormField required mods="field-50" label="Org Name" name="name" type={FormFieldType.TEXT} />
            <FormField
              required
              mods="field-50"
              label="Sport"
              name="sport"
              type={FormFieldType.SELECT}
              options={sports}
            />

            <FormField
              required
              mods="field-50 sui-mt-2"
              label="Registration Version"
              name="registrationType"
              type={FormFieldType.SELECT}
              options={[
                { label: 'Reg 2.0 only', value: 'reg2' },
                { label: 'Reg 1.5 only', value: 'reg1' },
                { label: 'Reg 1.5 and Reg 2.0', value: 'both' },
              ]}
            />
          </section>

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-w-full">Owner</h3>

          <section className="fields-group sui-p-2">
            {!editOrg || (editOrg && (!editOrg.ownerFirstName || !editOrg.ownerLastName || !editOrg.ownerEmail)) ? (
              <>
                <h4 className="sui-heading-sm sui-my-2 sui-w-full">Current Owner</h4>
                <FormField
                  required
                  mods="field-50"
                  label="First Name"
                  name="ownerFirstName"
                  type={FormFieldType.TEXT}
                />
                <FormField required mods="field-50" label="Last Name" name="ownerLastName" type={FormFieldType.TEXT} />
                <FormField
                  required
                  mods="field-50 sui-mt-2"
                  label="Email"
                  name="ownerEmail"
                  type={FormFieldType.EMAIL}
                />
              </>
            ) : (
              <div className="sui-flex">
                <div className="sui-flex-1 sui-pr-2">
                  <h4 className="sui-heading-sm sui-w-full sui-mb-1">Current Owner</h4>
                  <div className="sui-mb-2">
                    <p className="sui-body">
                      {editOrg.ownerFirstName} {editOrg.ownerLastName}
                    </p>
                    <p className="sui-body">{editOrg.ownerEmail}</p>
                  </div>
                </div>
                <div className="sui-flex-1">
                  <h4 className="sui-heading-sm sui-w-full">Transfer Ownership</h4>
                  <p className="sui-body">
                    *Any changes to email will send a new invite and void all existing invites. Please inform the
                    customer to avoid confusion.
                  </p>
                  <FormField mods="sui-mt-1" label="New Owner Email" name="newOwnerEmail" type={FormFieldType.EMAIL} />
                </div>
              </div>
            )}
          </section>

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Location</h3>
          <section className="fields-group sui-p-2">
            <FormField
              required
              mods="field-50"
              label="Country (Determines default currency for payments)"
              name="country"
              type={FormFieldType.SELECT}
              options={countries}
            />
            <FormField
              required
              mods="field-50"
              label="Zip/Postal Code (For local weather forcasts)"
              name="zipCode"
              type={FormFieldType.TEXT}
            />
            <FormField
              required
              mods="field-50 sui-mt-2"
              label="Time Zone"
              name="timezone"
              type={FormFieldType.SELECT}
              options={timezones}
            />
          </section>

          {canAddLegacyOrgs && (
            <>
              <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Legacy Season</h3>
              <section className="fields-group sui-p-2">
                <label className="sui-label sui-mb-1">Legacy orgs added (you can't remove them):</label>
                <div className="sui-flex sui-mb-3">
                  {editOrg?.legacyOrgs.map((x, i) => (
                    <Pill key={x.value} mods={i ? 'sui-ml-2' : ''}>
                      <p>{x.label}</p>
                    </Pill>
                  ))}
                </div>

                <LookupField
                  isMulti
                  type="number"
                  name="additionalLegacyOrgUuids"
                  onSearch={onSearchLegacyOrg}
                  options={legacyOrgOptions}
                  label="Do you want to add additional legacy seasons to this org from other legacy organizations? Example: multiple Clubs have merged to create one organization. Enter additional UUID numbers below to link legacy seasons with this organization. UUIDs can only be used once, either to link seasons or as the primary UUID number added in the field above."
                />
              </section>
            </>
          )}

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Mailing Address</h3>
          <section className="fields-group sui-p-2">
            <FormField mods="sui-w-full" label="Address 1" name="mailingAddress" type={FormFieldType.TEXT} />
            <FormField mods="sui-w-full sui-mt-2" label="Address 2" name="mailingAddress2" type={FormFieldType.TEXT} />
            <FormField mods="field-50 sui-mt-2" label="City" name="mailingCity" type={FormFieldType.TEXT} />
            <FormField mods="field-50 sui-mt-2" label="State/Province" name="mailingState" type={FormFieldType.TEXT} />
            <FormField
              mods="field-50 sui-mt-2"
              label="Country"
              name="mailingCountry"
              type={FormFieldType.SELECT}
              options={countries}
            />
            <FormField
              mods="field-50 sui-mt-2"
              label="Zip/Postal Code"
              name="mailingZipCode"
              type={FormFieldType.TEXT}
            />
            <FormField
              mods="sui-w-full sui-mt-2"
              label="Apply the org address to all active seasons. Updating the season address may change offline payment instructions."
              name="updateAllSeasonsAddress"
              type={FormFieldType.CHECKBOX}
            />
          </section>

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Payment</h3>
          <section className="fields-group sui-p-2">
            <div className="sui-w-full fields-group">
              <FormField
                mods="field-50"
                label="Payment Provider"
                name="paymentProvider"
                type={FormFieldType.SELECT}
                options={[{ label: 'stripe', value: 'stripe' }]}
              />
              <FormField
                required
                mods="field-50"
                label="Business Type"
                name="businessType"
                type={FormFieldType.SELECT}
                options={[
                  { label: 'Non-Profit', value: 'non-profit' },
                  { label: 'Individual', value: 'individual' },
                  { label: 'Corporation', value: 'corporation' },
                ]}
              />
            </div>
            <h4 className="sui-heading-sm sui-my-2 sui-w-full">Pricing & Rates</h4>
            <div className="sui-w-full fields-group">
              <FormField
                mods="field-50"
                label="New Credit Card Processing Rate (%)"
                name="creditCardProcessingFeeRate"
                type={FormFieldType.NUMBER}
              />
              <FormField
                mods="field-50"
                label="New Credit Card Processing Fee (ex 0.99)"
                name="creditCardProcessingFeeBase"
                type={FormFieldType.NUMBER}
              />
              <FormField
                mods="field-50 sui-mt-2"
                label="New ACH Processing Rate (%)"
                name="achProcessingFeeRate"
                type={FormFieldType.NUMBER}
              />
              <FormField
                mods="field-50 sui-mt-2"
                label="New ACH Processing Fee (ex 0.99)"
                name="achProcessingFeeBase"
                type={FormFieldType.NUMBER}
              />
            </div>
            <h4 className="sui-heading-sm sui-my-2 sui-w-full">Plan Information</h4>
            <div className="sui-w-full fields-group">
              <FormField
                required
                mods="field-50"
                label="Plan"
                name="plan"
                type={FormFieldType.SELECT}
                options={[
                  { label: 'TeamSnap for Business', value: '60' },
                  { label: 'TeamSnap for Business with Website Toolkit', value: '61' },
                  { label: 'TeamSnap for Business with Competition Management', value: '62' },
                  { label: 'TeamSnap for Business with Competition Management and Website Toolkit', value: '63' },
                  { label: '21 Day Free Trial', value: '17' },
                ]}
              />
              <FormField
                mods="field-50"
                label="Plan Paid for This Number of Teams"
                name="numberOfTeamsPaidFor"
                type={FormFieldType.NUMBER}
              />

              <FormField
                required
                mods="field-50 sui-mt-2"
                label="Player Count"
                name="playerCount"
                type={FormFieldType.NUMBER}
              />
            </div>
            <h4 className="sui-heading-sm sui-my-2 sui-w-full">Content</h4>
            <FieldArray name="contentEntitlements" render={
              () => (
                <div className="sui-w-full fields-group">
                  {contentEntitlements.map((ce) => (
                    <FormField key={ce.entitlementName} name={`contentEntitlements.${ce.entitlementName}`}
                      label={ce.displayName}
                      mods="field-50" 
                      type={FormFieldType.CHECKBOX}
                    />
                  ))}
                </div>
              )
            }/>
          </section>

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Competition</h3>
          <section className="fields-group sui-p-2">
            <LookupField
              name="tournamentOrgId"
              onSearch={onSearchTournamentOrg}
              options={tournamentOrgOptions}
              type="number"
              label="If this Club & League organization also has a TeamSnap Tournaments [TST] organization, you can connect the accounts by entering the TST ID in the field below. This will display any events created in the TST org in the Org Calendar view"
            />
            <FormField mods="sui-w-full" label="Website URL" name="websiteUrl" type={FormFieldType.TEXT} />
          </section>

          <h3 className="sui-text-desktop-8 sui-mt-5 sui-mb-1">Other Information</h3>
          <section className="fields-group sui-p-2">
            <FormField mods="sui-w-full" label="Notes" name="notes" type={FormFieldType.TEXTAREA} />
            <FormField
              mods="sui-w-full sui-mt-2"
              label="This is an Internal Test Org (exclude from data analytics)"
              name="isTest"
              type={FormFieldType.CHECKBOX}
            />
          </section>

          <footer className="sui-w-full sui-flex sui-justify-end sui-mt-2">
            <LabelButton
              data-testid="cancel-button"
              className="sui-mr-2"
              size="small"
              variantType="secondary"
              onClick={() => onClose(false)}
            >
              Cancel
            </LabelButton>
            <LabelButton data-testid="save-button" size="small" type="submit">
              Save organization
            </LabelButton>
          </footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateOrganizationModal;
