import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Organization, OrganizationsService } from 'core/api'
import { AsyncState } from 'core/types'
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<Organization[]> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

interface GetOrganizationsFilters {
  searchTerm?: string,
  tsDivisionId?: number
}

export const loadOrganizationsByFilter = createAsyncThunk('organizations/', (filters: GetOrganizationsFilters) => {
  const { tsDivisionId, searchTerm } = filters
  return handleErrors(() => OrganizationsService.getOrganizations(tsDivisionId, searchTerm));
})

export const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadOrganizationsByFilter.pending, asyncPendingReducer);
    builder.addCase(loadOrganizationsByFilter.fulfilled, (state, action) => asyncFulfilledReducer(state, action))
  }
})

export const useOrganizationsStateSelector = () => useAppSelector((state) => state.organizations)
export const useOrganizationsSelector = () => useAppSelector((state) => state.organizations.data)

export default slice.reducer
