/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NamedParameters_typeof_translateBillingFrequency_ = {
    capability: NamedParameters_typeof_translateBillingFrequency_.capability;
};

export namespace NamedParameters_typeof_translateBillingFrequency_ {

    export enum capability {
        UNKNOWN = 'unknown',
        DAILY = 'daily',
        THREE_DAY = 'three_day',
        WEEK = 'week',
        MONTH = 'month',
        THREE_MONTH = 'three_month',
        SIX_MONTH = 'six_month',
        YEAR = 'year',
        LIFETIME = 'lifetime',
    }


}

