/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSubscriptionInfoForUserResponse } from '../models/GetSubscriptionInfoForUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * Returns subscriptions for a user
     * @param userId
     * @returns GetSubscriptionInfoForUserResponse Success
     * @throws ApiError
     */
    public static subscriptions(
        userId?: number,
    ): CancelablePromise<GetSubscriptionInfoForUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/',
            query: {
                'userId': userId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
