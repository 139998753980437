/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SyncSeasonParticipationParams } from '../models/SyncSeasonParticipationParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberSeasonParticipantsService {

    /**
     * Syncs season participation for an organization.
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static syncSeasonParticipation(
        requestBody?: SyncSeasonParticipationParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/member_season_participants/sync_season_participation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized`,
                500: `Sever Error`,
            },
        });
    }

}
